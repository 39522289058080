:root {
  --color-background: #f5f5f9;
  --color-scrollbar: rgb(224, 224, 224);
  --color-scrollbar-hover: #bbb;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: #d9d4c0;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #6e2829;
  border-radius: 10px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: #d9d4c0;
  border-radius: 10px;
}
* {
  scroll-behavior: smooth;
}

body {
  /* background-color: var(--color-background); */
  background-color: '#fff';
  scroll-behavior: smooth;
}

@-webkit-keyframes click_animation {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.3;
  }
  to {
    -webkit-transform: scale(5);
    transform: scale(5);
    opacity: 0;
  }
}

@keyframes click_animation {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.3;
  }
  to {
    -webkit-transform: scale(5);
    transform: scale(5);
    opacity: 0;
  }
}
